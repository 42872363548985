.door-widget {
  margin-top: 110px;

  &--maintenance {
    margin-top: 0;
  }

  &__last-used {
    color: #2ecc71;
    font-weight: 500;
  }

  &__pohina-factor {
    color: #2ecc71;
  }

  &__error-banner {
    padding: 1.5rem 0.75rem;
    border: 1px solid #c0392b;
    border-radius: 3px;
    margin: 2rem 0;
  }

  &__maintenance-banner {
    padding: 1.5rem 0.75rem;
    border: 1px solid #f39c12;
    border-radius: 3px;
    margin: 2rem 0;
  }

  &__maintenance-title {
    font-weight: bold;
    margin: 0;
    margin-bottom: 1em;
  }

  &__maintenance-message {
    margin: 0;
  }
}
