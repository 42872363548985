@use '../common' as common;

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: common.$text-color;

  &__container {
    max-width: 640px;
    width: 100vw;
    // padding for mobile UI
    padding: 5vmin 2rem 5vmin 2rem;
    box-sizing: border-box;
  }

  &__banner {
    text-align: left;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  &__nav-bar {
    margin: 32px 0;
  }
}

.header {
  display: flex;

  & &__language-switcher {
    margin-left: auto;
  }
}
