@use '../common' as common;

.language-switcher {
  &__select {
    // Begin copypasted reset builtin <select> styles: https://moderncss.dev/custom-select-styles-with-pure-css/
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    // End copypasted reset

    width: auto;
    color: inherit;
    font-size: 1rem;
    padding: 0.1rem 1rem 0.4rem 1rem;
    cursor: pointer;
  }

  &__select option {
    color: common.$text-color;
    background-color: common.$background-color;
  }

  position: relative;

  // separate border in container's pseudoelement so that we can separate
  // the border width from the <select> padding
  // -> we want more padding to increase click area so it's nicer to use,
  // but don't want an ugly big border!
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 20%;
    right: 20%;
    height: 0;
    border-bottom: 2px solid common.$text-color;
  }
}
