.staggered-in-transition {
  @for $i from 1 to 3 {
    & > :nth-child(#{$i}) {
      transform: translateY(6vh);
      opacity: 0;

      transition: transform 500ms cubic-bezier(0, 0, 0.1, 1),
        opacity 700ms cubic-bezier(0, 0, 0.4, 1);
      transition-delay: #{$i * 50}ms;
      will-change: transform, opacity;
    }

    &--mounted > :nth-child(#{$i}) {
      opacity: 1;
      transform: translateY(0);
      will-change: auto;
    }
  }
}
